<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="5"></top>
      <div class="container-box width-100">
        <div class="main-box">
          <p class="fs-mid-big black fw-mid margin-bs">{{ learnVideo.title }}</p>
          <p class="detail-text">
            <span>主讲人：{{ learnVideo.author }}</span
            ><span>已学习人数：{{ learnVideo.viewsNum }}</span
            ><span>发表时间：{{ learnVideo.pubdate }}</span>
          </p>
          <div class="video textAlign-c">
            <video :src="learnVideo.video" width="100%" height="100%" controls></video>
          </div>
          <div v-if="learnVideo.blurb" class="width-100 line-height2 fs-mid darkgrey">{{ learnVideo.blurb }}</div>
        </div>
        <div class="width-100 recommendation-box">
          <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
            <p class="title">相关<span class="theme-blue">推荐</span></p>
            <p @click="nextBatch" class="fs-big darkgrey opacity-8">换一批<i class="el-icon-refresh margin-l fs-big"></i></p>
          </div>
          <el-row>
            <el-col :span="8" v-for="(item, index) in aboutLearnVideoList.slice(0, 3)" :key="index"
              ><div class="grid-content">
                <el-image class="picture margin-b" :src="item.image" fit="cover"></el-image>
                <p class="width-100 fs-big black fw-mid cut-text">{{ item.title }}</p>
                <div class="width-100 margin-ts flex-row justify-content-spaceBetween align-items-center">
                  <p class="fs-mid darkgrey opacity-8">共{{ item.viewsNum }}人学习</p>
                  <el-button @click="handleDetail(item)" type="primary" size="small">观看学习</el-button>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { learnVideoInfo, learnVideoList } from '@/api/TranLearnVideo';
export default {
  name: 'onlineLearningDetail',
  components: {
    top,
    bottom
  },
  data() {
    return {
      learnVideoId: null,
      learnVideo: {},
      // 相关推荐
      aboutLearnVideoList: [],
      // 相关推荐查询参数
      queryParams: {
        pageNum: 0,
        pageSize: 3
      }
    };
  },
  created() {
    this.learnVideoId = this.$route.query.id;
    learnVideoInfo(this.learnVideoId).then((res) => {
      this.learnVideo = res.data;
      this.nextBatch();
    });
  },
  methods: {
    // 换一批
    nextBatch() {
      // 1. 从第一篇开始分页
      this.aboutLearnVideoList = [];
      this.queryParams.pageNum++;
      learnVideoList(this.queryParams).then((res) => {
        console.log(res);
        let retry = true;
        // 2. 遇到当前作品直接跳过，pageNum++
        res.rows.forEach((item) => {
          if (item.id === this.learnVideo.id) {
            this.retry = false;
            this.nextBatch();
          } else {
            let image = item.image;
            if (item.image && !item.image.startsWith('http')) {
              image = `${process.env.VUE_APP_IMAGE_PREFIX}${image}`;
            }
            item.image = image;
          }
        });
        // 3. 当rows.length < pageSize时，说明已经到头了，重新从第一页开始查询
        if (retry) {
          if (res.rows.length < this.queryParams.pageSize) {
            this.queryParams.pageNum = 1;
            this.nextBatch();
          } else {
            this.aboutLearnVideoList = res.rows;
          }
        }
      });
    },
    // 相关推荐点击
    handleDetail(item) {
      this.$router.replace({ path: '/onlineLearningDetail', query: { id: item.id } });
      window.location.reload();
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0 80px;
    .main-box {
      margin: 0px 0 60px;
      padding: 30px 50px;
      border-radius: 10px;
      background: white;
      .detail-text {
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 20px;
        span {
          font-size: 16px;
          color: #888888;
          margin-right: 40px;
        }
      }
      .video {
        width: 100%;
        height: 700px;
        padding: 60px 10% 40px;
      }
    }
    .recommendation-box {
      .title,
      span {
        font-size: 36px;
        font-weight: bold;
      }
    }
    .grid-content {
      width: 94%;
      padding: 20px 20px;
      margin: 15px 3%;
      background: white;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(133, 188, 240, 0.25);
      .picture {
        width: 100%;
        height: 300px;
      }
    }
  }
}
</style>
