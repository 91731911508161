import request from '@/util/request';

/**
 * 版权交易在线学习 接口
 * @Author Hubx
 */

// 免登录 获取详情
export function learnVideoInfo(id) {
  return request({
    url: `/tran/TranLearnVideo/ua/` + id,
    method: 'get'
  });
}

// 免登录 获取列表
export function learnVideoList(params) {
  return request({
    url: `/tran/TranLearnVideo/ua/list`,
    method: 'get',
    params: params
  });
}

// 免登录 保存记录
export function learnVideoSave(data) {
  return request({
    url: `/tran/TranLearnVideo/ua/saveOrUpdate`,
    method: 'post',
    data: data
  });
}
